import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Post } from "./Post";

export const Posts = ({ data = [], query }) => {
  const postsByYear = useMemo(() => {
    const collection = {};

    data.forEach((post) => {
      const year = dayjs(post.node.frontmatter.datePublished).format("YYYY");
      collection[year] = [...(collection[year] || []), post];
    });

    return collection;
  }, [data]);
  const years = useMemo(
    () => Object.keys(postsByYear).reverse(),
    [postsByYear]
  );
  return years.map((year) => (
    <section key={year} className="my-3">
      <h2 className="border-b-2">{year}</h2>
      <div className="posts">
        {postsByYear[year].map(({ node }) => (
          <Post key={node.id} node={node} query={query} />
        ))}
      </div>
    </section>
  ));
};
