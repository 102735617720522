import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { graphql, Link } from "gatsby";
import { Posts } from "../components/Posts";

const IndexPage = ({ data }) => {
  const posts = data.latest.edges;
  return (
    <Layout>
      <SEO title="Index" />
      <div className="flex justify-around mt-10 flex-wrap">
        <section className="mr-4">
          <div className="p-6 max-w-sm bg-white rounded-xl shadow-lg flex items-center space-x-4 skew-y-2">
            <div className="shrink-0">{"{...}"}</div>
            <div>
              <div className="text-xl font-medium text-black">
                Hi, Homo sapiens 👋
              </div>
              <p className="text-gray-600">Welcome to my site!</p>
            </div>
          </div>
        </section>
        <section className="flex-grow">
          <h3 className="flex justify-between border-b-2">
            <span>Latest Articles</span> <Link to="/posts">View All</Link>
          </h3>
          <Posts data={posts} />
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    latest: allMdx(
      limit: 10
      sort: { fields: [frontmatter___datePublished], order: DESC }
    ) {
      edges {
        node {
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            datePublished(formatString: "YYYY-MM-DD HH:mm:ss")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
