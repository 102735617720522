import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.locale("zh-cn");

const Tags = ({ tags }) => {
  return (
    <ul className="flex gap-3 flex-wrap">
      {tags.map((tag, index) => {
        return (
          <li className="before:content-['#'] before:text-blue-500">
            <Link to={`/tags/${tag}/`} key={index}>
              {tag}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export const Post = ({ node, query }) => {
  const { datePublished, tags } = node.frontmatter;
  const timeToRead = `${node.timeToRead}`;
  const timeFromNow = dayjs(datePublished).fromNow();
  const diffTime = dayjs().diff(datePublished, "month");
  const isNew = diffTime <= 1;
  const infoCaption = `发表于 ${datePublished} | 阅读时长 ≈ ${timeToRead}分钟 | ${timeFromNow}`;
  const getTitle = (title, query) => {
    if (query) {
      const re = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
      const highlightStart = title.search(re);

      if (highlightStart !== -1) {
        const highlightEnd = highlightStart + query.length;

        return (
          <h3>
            {title.slice(0, highlightStart)}
            <strong className="highlighted">
              {title.slice(highlightStart, highlightEnd)}
            </strong>
            {title.slice(highlightEnd)}
          </h3>
        );
      }
      return <h3>{title}</h3>;
    }
    return <h3>{title}</h3>;
  };

  return (
    <article className="my-5">
      <header>
        <h3>
          <Link to={node.fields.slug}>
            <span className="flex" style={{ alignItems: "baseline" }}>
              {isNew && <span>🆕</span>}
              {getTitle(node.frontmatter.title, query)}
            </span>
          </Link>
        </h3>
        <small>{infoCaption}</small>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </section>
    </article>
  );
};
